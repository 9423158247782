<template>
  <div class="container" ref="containerRef"></div>
</template>
<script>
import * as echarts from 'echarts'
import { Empty } from 'ant-design-vue'
import { getChartInterval } from '@/utils/util'
export default {
  components: {
    AEmpty: Empty
  },
  props: {
    params: {
      type: Array,
      default: () => {}
    },
    resize: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      option: {
        color: ['#088ee3'],
        title: {
          show: false,
          left: 'left',
          text: '月MPD',
          textStyle: {
            fontSize: 14
          },
          padding: [2, 0, 0, 3]
        },
        tooltip: {
          trigger: 'axis',
          formatter: '{c}'
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            fontSize: 9
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true
          }
        ]
      }
    }
  },
  watch: {
    params(newVal, oldVal) {
      console.log('month *** params', newVal)
      const isEmpty = !newVal || newVal.length === 0
      if (isEmpty) {
        this.myChart && this.myChart.dispose()
        this.myChart = null
        return
      }
      this.setOption(newVal)
    },
    resize(newVal, oldVal) {
      this.myChart && this.myChart.resize()
    }
  },
  mounted() {
    console.log('month *** mounted', this.params)
    this.loadChart()
  },
  methods: {
    setOption(data) {
      // const echarts = window.echarts
      // 判断图表库是否加载完成
      if (!echarts) {
        setTimeout(() => {
          this.setOption(data)
        }, 500)
        return
      }
      const weekArray = []
      const countArray = []
      if (data && data.length > 0) {
        let maxMiles = 0
        data.forEach((p) => {
          weekArray.push(p.particle)
          const mpdMonth = p.takeOverDay ? p.autoCtlOdom / 1000 / p.takeOverDay : p.autoCtlOdom / 1000
          console.log('mpdMonth', mpdMonth)
          const odo = Math.ceil(mpdMonth)
          if (odo > maxMiles) {
            maxMiles = odo
          }
          countArray.push(mpdMonth.toFixed(2))
        })
        weekArray.reverse()
        countArray.reverse()
        this.option.yAxis.interval = getChartInterval(maxMiles)
      }
      this.option.xAxis.data = weekArray
      this.option.series[0].data = countArray

      if (!this.myChart) {
        this.myChart = echarts.init(this.$refs.containerRef)
      }
      this.option && this.myChart.setOption(this.option)
    },
    loadChart() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.myChart = echarts.init(this.$refs.containerRef)
        })
      })
    }
  },
  beforeDestroy() {
    this.myChart && this.myChart.dispose()
    this.myChart = null
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>
